#SectionA {
    background: url(../imgs/bg-1.png) fixed center no-repeat;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  #SectionA .container {
    padding: 100px 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-left: 10vw;
  }
  @media (max-width: 574px) {
    #SectionA .container {
      flex-direction: column;
      gap: 50px;
    }
  }
  @media (min-width: 576px) {
    #SectionA .container {
      flex-direction: column;
      gap: 50px;
    }
  }
  @media (min-width: 768px) {
    #SectionA .container {
      flex-direction: column;
      gap: 50px;
    }
  }
  @media (min-width: 992px) {
    #SectionA .container {
      flex-direction: row;
    }
  }
  #SectionA .container .text p {
    color: #fff;
    font-size: 18px;
    font-weight: 500;
    line-height: 27px;
    padding: 10px 0;
    max-width: 530px;
  }
  #SectionA .container .logos {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }

img.parceiros{
    border-radius: 100px;
    width: 640px;
}

img.cred{
    border-radius: 100px;
    width: 640px;
}