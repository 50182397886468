#sectionC {
    background: url(../imgs/bg-1.png) fixed center no-repeat;
    background-size: cover;
    display: flex;
    justify-content: center;
  }
  #sectionC .container {
    padding: 100px 0;
  }
  #sectionC .container .title {
    font-weight: 300;
    font-size: 43px;
    text-align: center;
    color: #fff;
    margin-bottom: 80px;
  }
  #sectionC .container .title strong {
    font-weight: 900;
  }
  #sectionC .container .cards {
    display: flex;
    justify-content: space-evenly;
  }
  @media (max-width: 574px) {
    #sectionC .container .cards {
      flex-direction: column;
      align-items: center;
      gap: 50px;
    }
  }
  @media (min-width: 576px) {
    #sectionC .container .cards {
      flex-direction: column;
      align-items: center;
      gap: 50px;
    }
  }
  @media (min-width: 768px) {
    #sectionC .container .cards {
      flex-direction: column;
      align-items: center;
      gap: 50px;
    }
  }
  @media (min-width: 992px) {
    #sectionC .container .cards {
      flex-direction: row;
      align-items: flex-start;
    }
  }
  #sectionC .container .cards .card {
    width: 285px;
    text-align: center;
  }
  #sectionC .container .cards .card h3 {
    font-size: 18px;
    font-weight: 500;
    text-align: center;
    color: #fff;
    margin-top: 25px;
  }
  #sectionC .container .cards .card h3 strong {
    font-weight: 900;
  }
  #sectionC .container .rodape {
    padding-top: 80px;
  }
  #sectionC .container .rodape p {
    color: #00a0a9;
    font-size: 16px;
    font-weight: 800;
    text-align: center;
  }
