
#sectionE {
    background: url(../imgs/bg-4.jpg) top center no-repeat;
    background-size: cover;
    display: flex;
    justify-content: center;
  }
  #sectionE .container {
    padding: 100px 0;
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  #sectionE .container .title {
    font-weight: 300;
    font-size: 43px;
    text-align: center;
    color: #fff;
    margin-bottom: 80px;
  }
  #sectionE .container .title strong {
    font-weight: 900;
  }
  #sectionE .container .rowCard {
    max-width: 1400px;
    width: 1400px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 25px 0;
  }
  @media (max-width: 574px) {
    #sectionE .container .rowCard {
      flex-direction: column;
    }
  }
  @media (min-width: 576px) {
    #sectionE .container .rowCard {
      flex-direction: column;
    }
  }
  @media (min-width: 992px) {
    #sectionE .container .rowCard {
      flex-direction: row;
    }
  }
  @media (max-width: 574px) {
    #sectionE .container .rowCard .texto {
      max-width: 100%;
    }
  }
  @media (min-width: 576px) {
    #sectionE .container .rowCard .texto {
      max-width: 100%;
    }
  }
  @media (min-width: 992px) {
    #sectionE .container .rowCard .texto {
      max-width: 50%;
    }
  }
  #sectionE .container .rowCard .texto h3 {
    font-weight: 300;
    color: #0a98a0;
  }
  @media (max-width: 574px) {
    #sectionE .container .rowCard .texto h3 {
      font-size: 33px;
    }
  }
  @media (min-width: 576px) {
    #sectionE .container .rowCard .texto h3 {
      font-size: 43px;
    }
  }
  #sectionE .container .rowCard .texto h3 strong {
    font-weight: 900;
  }
  #sectionE .container .rowCard .texto p {
    font-size: 25px;
    margin-top: 30px;
    color: #fff;
    font-weight: 500;
  }
  #sectionE .container .rowCard .texto p strong {
    color: #0a98a0;
    font-weight: 900;
  }
  @media (max-width: 574px) {
    #sectionE .container .micInd,
  #sectionE .container .peqEmp {
      flex-direction: column;
      text-align: center;
    }
  }
  @media (min-width: 576px) {
    #sectionE .container .micInd,
  #sectionE .container .peqEmp {
      flex-direction: column;
      text-align: center;
    }
  }
  @media (min-width: 768px) {
    #sectionE .container .micInd,
  #sectionE .container .peqEmp {
      flex-direction: column;
      text-align: center;
    }
  }
  @media (min-width: 992px) {
    #sectionE .container .micInd,
  #sectionE .container .peqEmp {
      flex-direction: row-reverse;
      text-align: left;
    }
  }
  @media (max-width: 574px) {
    #sectionE .container .micEmp {
      text-align: center;
    }
  }
  @media (min-width: 576px) {
    #sectionE .container .micEmp {
      text-align: center;
    }
  }
  @media (min-width: 768px) {
    #sectionE .container .micEmp {
      text-align: center;
    }
  }
  @media (min-width: 992px) {
    #sectionE .container .micEmp {
      text-align: right;
    }
  }
  #sectionE .imagem img {
    max-width: 100%;
  }
  #sectionE .aviso {
    width: 100%;
    margin-top: 100px;
    background-color: #44b0ba;
    font-size: 20px;
    line-height: 30px;
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  @media (max-width: 574px) {
    #sectionE .aviso {
      flex-direction: column;
      padding: 30px 25px;
      gap: 25px;
    }
  }
  @media (min-width: 576px) {
    #sectionE .aviso {
      flex-direction: column;
      padding: 30px 25px;
      gap: 25px;
    }
  }
  @media (min-width: 768px) {
    #sectionE .aviso {
      padding: 30px 15px;
      flex-direction: row;
      gap: 50px;
    }
  }
  #sectionE .aviso .imgAviso {
    text-align: right;
  }
  #sectionE .aviso .textoAviso {
    color: #233342;
  }
