#header {
    background: url(../imgs/banner.jpg) center center no-repeat;
    background-size: cover;
    height: 100vh;
    max-height: 100vh;
  }
#header .container {
    margin-left: 15vw;
    margin-top: 10vh;
    text-align:start;
    display: inline-block;
    vertical-align: middle;
}
  #header img {
    max-width: 45%;
    padding: 50px;
    display: inline-block;
    vertical-align: bottom;
  }
  .texto{
    width: 40%;
    display: inline;
    vertical-align: bottom;
  }
  #header h1 {
    font-weight: normal;
    font-size: 61px;

    color: #fff;
  }
  @media (max-width: 574px) {
    #header img {
      max-width: 95%;
    }
    #header h1 {
      font-size: 50px;
    }
  }
  @media (max-width: 576px) {
    #header img {
      max-width: 90%;
    }
    #header h1 {
      font-size: 58px;
    }
  }
  @media (max-width: 768px) {
    #header img {
      font-size: 58px;
      display: inline;

    }
    #header h1 {
      font-size: 61px;
    }
  }
  @media (max-width: 992px) {
    #header img {
      max-width: 70%;
    }
  }