
#sectionD {
    background: url(../imgs/bg-3.jpg) fixed center no-repeat;
    background-size: cover;
    display: flex;
    justify-content: center;
    width: 100%;
  }
  #sectionD .container {
    padding: 100px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 50px;
    text-align: center;
  }
  #sectionD .container .header {
    color: #fff;
  }
  #sectionD .container .header .titulo {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: left;
    gap: 50px;
  }
  @media (max-width: 574px) {
    #sectionD .container .header .titulo {
      flex-direction: column;
      text-align: center;
    }
  }
  @media (min-width: 576px) {
    #sectionD .container .header .titulo {
      flex-direction: column;
      text-align: center;
    }
  }
  @media (min-width: 992px) {
    #sectionD .container .header .titulo {
      flex-direction: row;
    }
  }
  #sectionD .container .header .titulo img {
    height: 100%;
  }
  #sectionD .container .header h2 {
    font-size: 41px;
    font-weight: 300;
    margin: 30px 0;
  }
  #sectionD .container .header h2 strong {
    font-weight: 900;
  }
  #sectionD .container .header p {
    font-size: 18px;
    font-weight: 500;
    line-height: 27px;
  }
  #sectionD .container .header p strong {
    font-weight: 900;
  }
  #sectionD .container .content {
    display: flex;
  }
  @media (max-width: 574px) {
    #sectionD .container .content {
      flex-direction: column;
      align-items: center;
    }
  }
  @media (min-width: 576px) {
    #sectionD .container .content {
      flex-direction: column;
      align-items: center;
    }
  }
  @media (min-width: 992px) {
    #sectionD .container .content {
      flex-direction: row;
      align-items: flex-start;
    }
  }
  #sectionD .container .content .col {
    margin: 0 25px;
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  #sectionD .container .content .col h3 {
    font-size: 20px;
    font-weight: 800;
    color: #fff;
    margin-bottom: 15px;
  }
  #sectionD .container .content .col a.btn {
    text-decoration: none;
    color: #1b9fa7;
    font-size: 14px;
    font-weight: 700;
    margin-bottom: 30px;
    background-color: #212e3a;
    padding: 20px;
    text-align: center;
    border-radius: 30px;
    transition: color 0.4s ease-in-out, background-color 0.4s ease-in-out;
  }
  #sectionD .container .content .col a.btn:hover {
    color: #212e3a;
    background-color: #fff;
    transition: color 0.4s ease-in-out, background-color 0.4s ease-in-out;
  }
  #sectionD .container .content .col .btn {
    cursor: pointer;
  }